import tcPlushies from '@/assets/images/services/tc-plushies.png'
import { ContainerNarrow } from '@/components/styled/Container'
import { breakpoint } from '@/utils/styles'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

const Wrapper = styled.div`
  background-color: #591a65;
`

const Block = styled(ContainerNarrow)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 0 0 0;

  ${breakpoint} {
    flex-direction: row;
    padding: 0;
  }
`

const Image = styled.img`
  width: 100%;

  ${breakpoint} {
    margin-top: 6.5rem;
    max-width: 55%;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  ${breakpoint} {
    align-items: flex-start;
    padding: 6rem;
    width: 45%;
  }
`

export function BlockWithImageTC({
  children
}: {
  children: ReactNode
}): JSX.Element {
  return (
    <Wrapper>
      <Block>
        <TextWrapper>{children}</TextWrapper>
        <Image src={tcPlushies} alt="TokyoCatch plushies picture" />
      </Block>
    </Wrapper>
  )
}
