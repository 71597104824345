import arrowRightWhite from '@/assets/images/services/arrow-right-white.svg'
import arrowRight from '@/assets/images/services/arrow-right.svg'
import jhLogoColored from '@/assets/images/services/jh-logo-colored.svg'
import jhProducts from '@/assets/images/services/jh-products.png'
import nmBox from '@/assets/images/services/nm-box.png'
import nmLogoColored from '@/assets/images/services/nm-logo-colored.svg'
import scBox from '@/assets/images/services/sc-box.png'
import scLogoColored from '@/assets/images/services/sc-logo-colored.svg'
import tcLogoColored from '@/assets/images/services/tc-logo-colored.svg'
import ttBox from '@/assets/images/services/tt-box.png'
import ttLogoColored from '@/assets/images/services/tt-logo-colored.svg'
import ytBox from '@/assets/images/services/yt-box.png'
import ytLogoColored from '@/assets/images/services/yt-logo-colored.svg'
import Layout from '@/components/layouts/Layout'
import {
  BlockWithImage,
  ImagePosition
} from '@/components/pages/services/BlockWithImage'
import { BlockWithImageTC } from '@/components/pages/services/BlockWithImageTC'
import ButtonBlueOutline from '@/components/styled/ButtonBlueOutline'
import { ContainerFullWidth } from '@/components/styled/Container'
import { H1, H3 } from '@/components/styled/Typography'
import styled from '@emotion/styled'
import React from 'react'

const ServiceType = styled(H3)`
  color: #020247;
  margin-bottom: 2rem;
  white-space: nowrap;
`

const BrandLogo = styled.img`
  max-height: 50px;
`

const Description = styled.p`
  font-size: 18px;
  line-height: 27px;
  color: #555272;
  min-height: 8.5rem;
`

const ViewSiteWhite = styled(ButtonBlueOutline)`
  color: white;
  border: 1px solid white;
`

const ArrowRight = styled.img`
  margin-left: 1rem;
`

const Heading = styled(H1)``

export default function Services() {
  return (
    <Layout LayoutContainer={ContainerFullWidth}>
      <Heading>サービス</Heading>

      {/* Sakuraco */}
      <BlockWithImage
        backgroundColor="#FFFBF1"
        image={scBox}
        imageAlt="Sakuraco opened box"
        imagePosition={ImagePosition.Left}
      >
        <ServiceType>サブスクリプションサービス</ServiceType>
        <BrandLogo src={scLogoColored} alt="Sakuraco logo" />
        <Description>
          日本全国の老舗和菓子メーカーの和菓子や日本茶を定期課金にて全世界向けに販売する会員制サブスクボックスサービス。地方メーカーに海外販路を提供することで地方活性化への貢献も目指す。
        </Description>
        <ButtonBlueOutline href="https://sakura.co" target="_blank">
          サイトを見る
          <ArrowRight src={arrowRight} alt="" />
        </ButtonBlueOutline>
      </BlockWithImage>

      {/* TokyoTreat */}
      <BlockWithImage
        backgroundColor="#FFF0E8"
        image={ttBox}
        imageAlt="TokyoTreat opened box"
        imagePosition={ImagePosition.Right}
      >
        <ServiceType>サブスクリプションサービス</ServiceType>
        <BrandLogo src={ttLogoColored} alt="TokyoTreat logo" />
        <Description>
          日本のお菓子・ソフトドリンクを定期課金にて全世界向けに販売する会員制サブスクボックスサービス。主にメジャーブランドの期間限定や日本限定アイテムを取り扱っている。
        </Description>
        <ButtonBlueOutline href="https://tokyotreat.com" target="_blank">
          サイトを見る
          <ArrowRight src={arrowRight} alt="" />
        </ButtonBlueOutline>
      </BlockWithImage>

      {/* YumeTwins */}
      <BlockWithImage
        backgroundColor="#FCF5FF"
        image={ytBox}
        imageAlt="YumeTwins opened box"
        imagePosition={ImagePosition.Left}
      >
        <ServiceType>サブスクリプションサービス</ServiceType>
        <BrandLogo src={ytLogoColored} alt="YumeTwins logo" />
        <Description>
          日本のキャラクター雑貨を定期課金にて全世界向けに販売する会員制サブスクボックスサービス。有名キャラクターとのコラボレーションにてブランドオリジナルアイテム作成の実績多数あり。
        </Description>
        <ButtonBlueOutline href="https://yumetwins.com" target="_blank">
          サイトを見る
          <ArrowRight src={arrowRight} alt="" />
        </ButtonBlueOutline>
      </BlockWithImage>

      {/* nomakenolife */}
      <BlockWithImage
        backgroundColor="#FFE4E4"
        image={nmBox}
        imageAlt="nomakenolife opened box"
        imagePosition={ImagePosition.Right}
      >
        <ServiceType>サブスクリプションサービス</ServiceType>
        <BrandLogo src={nmLogoColored} alt="nomakenolife logo" />
        <Description>
          日本と韓国のコスメやビューティーグッズを全世界向けに販売する会員制サブスクボックスサービス。
        </Description>
        <ButtonBlueOutline href="https://nomakenolife.com" target="_blank">
          サイトを見る
          <ArrowRight src={arrowRight} alt="" />
        </ButtonBlueOutline>
      </BlockWithImage>

      {/* JapanHaul */}
      <BlockWithImage
        backgroundColor="#FFFFFF"
        image={jhProducts}
        imageAlt="JapanHaul products"
        imagePosition={ImagePosition.Left}
      >
        <ServiceType>ソーシャルコマース</ServiceType>
        <BrandLogo src={jhLogoColored} alt="JapanHaul logo" />
        <Description>
          日本の高クオリティーな商品を全世界から購入できるEコマースサイト。最新アイテムや限定品を多く取り揃え、配送スピードにもこだわっている。
        </Description>
        <ButtonBlueOutline href="https://japanhaul.com" target="_blank">
          サイトを見る
          <ArrowRight src={arrowRight} alt="" />
        </ButtonBlueOutline>
      </BlockWithImage>

      {/* TokyoCatch */}
      <BlockWithImageTC>
        <ServiceType style={{ color: 'white' }}>
          オンラインクレーンゲーム
        </ServiceType>
        <BrandLogo src={tcLogoColored} alt="TokyoCatch logo" />
        <Description style={{ color: 'white' }}>
          スマートフォンアプリとウェブで遊べる英語・日本語対応のオンラインクレーンゲーム。獲得した景品は無料で配送。
        </Description>
        <ViewSiteWhite href="https://tokyocatch.com" target="_blank">
          サイトを見る
          <ArrowRight src={arrowRightWhite} alt="" />
        </ViewSiteWhite>
      </BlockWithImageTC>
    </Layout>
  )
}
