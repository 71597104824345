import { ContainerNarrow } from '@/components/styled/Container'
import { breakpoint } from '@/utils/styles'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

export enum ImagePosition {
  Left = 'left',
  Right = 'right'
}

const Wrapper = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: hidden;
`

const Block = styled(ContainerNarrow)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;

  ${breakpoint} {
    flex-direction: row;
    padding: 2rem 0;
  }
`

const Image = styled.img<{ imagePosition: ImagePosition }>`
  width: 100%;

  ${breakpoint} {
    order: ${({ imagePosition }) =>
      imagePosition === ImagePosition.Left ? '1' : '2'};
    width: 55%;
  }
`

const TextWrapper = styled.div<{ imagePosition: ImagePosition }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  ${breakpoint} {
    order: ${({ imagePosition }) =>
      imagePosition === ImagePosition.Left ? '2' : '1'};
    align-items: flex-start;
    padding: 6rem;
    width: 45%;
  }
`

export function BlockWithImage({
  backgroundColor,
  image,
  imageAlt,
  imagePosition,
  children
}: {
  backgroundColor: string
  image: string
  imageAlt: string
  imagePosition: ImagePosition
  children: ReactNode
}): JSX.Element {
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Block>
        <Image src={image} alt={imageAlt} imagePosition={imagePosition} />
        <TextWrapper imagePosition={imagePosition}>{children}</TextWrapper>
      </Block>
    </Wrapper>
  )
}
